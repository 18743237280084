import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './LandingPage.css';
import appLogo from '../assets/logo.svg';
import calendarIcon from '../assets/icons/calendars.svg';
import coordinationIcon from '../assets/icons/coordination.svg';
import networkIcon from '../assets/icons/network.svg';
import connectionsIcon from '../assets/icons/connections.svg';

function LandingPage() {
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;

    // Fire-and-forget fetch call
    fetch('/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    }).catch((error) => {
      console.error('Error in subscription:', error);
    });
  
    // Redirect to Typeform immediately
//    window.location.href = 'https://ucvj0fsozmy.typeform.com/signup';
    navigate('/join-beta');
  };

  const goToBetaSignup = () => {
    navigate('/join-beta');
  }

  const scrollToSignup = () => {
    document.getElementById('signup').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>Parenting Is Easier Together</title>
      </Helmet>

      {/* Sticky Navbar */}
      <nav id="navbar">
        <div className="navbar-content">
          <div className="logo-content">
            <img src={appLogo} alt="Company Logo" className="logo" />
          </div>
          <button className="nav-button" onClick={goToBetaSignup}>Join Beta</button>
        </div>
      </nav>

      {/* Hero Section */}
      <section id="hero">
        <div id="hero-content">
          <h1>Parenting Is Easier Together</h1>
          <p>Even the most organized parents need help.</p> 
          <p>With simplified tracking from Plii and other parents filling in the gaps, you can stay on top of what’s important without extra hassle.</p>
          <button className="hero-button" onClick={goToBetaSignup}>Join Plii Beta</button>
        </div>
      </section>

      {/* Features as Separate Sections */}
      <section id="features">
        <div className="feature-card">
          <div className="icon-container">
            <img src={calendarIcon} alt="Calendar Icon" className="feature-icon" />
          </div>
          <h3>Simplify<br></br>Your Schedule</h3>
          <p>Plii makes managing your calendar easy.</p>
        </div>

        <div className="feature-card">
          <div className="icon-container">
            <img src={coordinationIcon} alt="Coordination Icon" className="feature-icon" />
          </div>
          <h3>Easy<br></br>Coordination</h3>
          <p>Plan with other parents without the usual back-and-forth and hesitation.</p>
        </div>

        <div className="feature-card">
          <div className="icon-container">
            <img src={networkIcon} alt="Network Icon" className="feature-icon" />
          </div>
          <h3>Fill in<br></br>the Gaps</h3>
          <p>Help each other by sharing and receiving events to fill in scheduling gaps.</p>
        </div>

        <div className="feature-card">
          <div className="icon-container">
            <img src={connectionsIcon} alt="Connections Icon" className="feature-icon" />
          </div>
          <h3>Safe<br></br>Connections</h3>
          <p>Connect with like-minded parents while controlling what you share.</p>
        </div>
      </section>

      {/* Email Signup (Repeated at the Bottom) */}
      <section id="signup">
        <h2 className="header">Join Our Community</h2>
        <p className="prompt">Sign up to be the first to know when we launch.</p>
        <button className="signup-button" onClick={goToBetaSignup}>Join Beta</button>
        {/* <form className="signup-container" onSubmit={handleSubmit}>
          <input type="email" name="email" placeholder="Email Address" required />
          <button type="submit">Join Beta</button>
        </form> */}
        <p className="privacy-note">*We respect your privacy and won't share your information.</p>
      </section>
      {/* Footer */}
      <footer>
        <div className="footer-left">
          <p>&copy; 2024 plii LLC. All rights reserved.</p>
        </div>
        <div className="footer-right">
          <p>
            <a href="/privacy-policy">Privacy Policy</a>| 
            <a href="/terms-and-conditions">Terms and Conditions</a>
          </p>
        </div>
      </footer>
    </>
  );
}

export default LandingPage;
