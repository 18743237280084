import React, { useEffect, useState } from 'react';
import { isAppIntroductionComplete, setAppIntroductionComplete } from '../../introduction/preferences';
import SplashScreen from './SplashScreen';
import ScrollableIntroduction from './ScrollableIntroduction';

interface AppIntroductionManagerProps {
  onComplete: () => void;
}

const AppIntroductionManager: React.FC<AppIntroductionManagerProps> = ({ onComplete }) => {

  const completeIntroduction = async () => {
    await setAppIntroductionComplete();
    onComplete();
  };

  return <ScrollableIntroduction onComplete={completeIntroduction} />;
};

export default AppIntroductionManager;
