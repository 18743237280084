import React, { useState } from 'react';
import styles from './ScrollableIntroduction.module.css';

interface ScrollableIntroductionProps {
  onComplete: () => void;
}

const ScrollableIntroduction: React.FC<ScrollableIntroductionProps> = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const onboardingSteps = [
    { title: 'Connect with Like-Minded Parents', description: 'Find and connect with parents near you.' },
    { title: 'Plan Playdates Effortlessly', description: 'Simplify scheduling and reminders for family activities.' },
    { title: 'Stay Organized', description: 'Get weekly digests and stay on top of your schedule.' },
  ];

  const handleNext = () => {
    if (currentStep < onboardingSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onComplete();
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{onboardingSteps[currentStep].title}</h2>
      <p className={styles.description}>{onboardingSteps[currentStep].description}</p>
      <button onClick={handleNext} className={styles.button}>
        Next
      </button>
    </div>
  );
};

export default ScrollableIntroduction;
