import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
// @ts-ignore
import Login from './components/Login';
// @ts-ignore
import HomePage from './pages/HomePage';
// @ts-ignore
import TermsAndConditions from './components/TermsAndConditions';
// @ts-ignore
import PrivacyPolicy from './components/PrivacyPolicy';
// @ts-ignore
import JoinBeta from './components/JoinBeta';
import { ToastContainer, toast } from 'react-toastify';
// @ts-ignore
import LandingPage from './components/LandingPage';
import { CHECK_SESSION } from './graphql/queries/sessionQueries';
import 'react-toastify/dist/ReactToastify.css';
import { isWeb, isNative } from './platform/platform';
import './App.css';
import { isAppIntroductionComplete, setAppIntroductionComplete, clearIntroductionStatus } from './introduction/preferences';
import AppIntroductionManager from './components/introduction/AppIntroductionManager';
import { SplashScreen as CapacitorSplashScreen } from '@capacitor/splash-screen';
import SplashScreen from './components/introduction/SplashScreen';

const localMode = false; 

// Define GraphQL query type for session check
interface User {
  email: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  onboardingStatus: { completed: boolean };
}

interface SessionData {
  checkSession: {
    authenticated: boolean;
    user: User | null;
  };
}

interface SessionVars {}

function deleteCookie(name: string): void {
  document.cookie = `${name}=; Max-Age=0; path=/;`;
}

function clearQueryParams(): void {
  const newUrl = window.location.origin + window.location.pathname;
  window.history.replaceState({}, document.title, newUrl);
}

function App(): JSX.Element {
  const [appInitialized, setAppInitialized] = useState(false);
  const { data, loading, error } = useQuery<SessionData, SessionVars>(CHECK_SESSION, {
    fetchPolicy: 'network-only',
    skip: localMode,
  });
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(localMode ? true : null);
  const [isIntroductionDone, setIsIntroductionDone] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(
    localMode
      ? {
          email: 'localuser@example.com',
          firstName: 'Local',
          lastName: 'User',
          profilePictureUrl: 'https://via.placeholder.com/150',
          onboardingStatus: { completed: true },
        }
      : null
  );
  const location = useLocation();

  const platform = isWeb() ? 'web' : isNative() ? 'native' : 'unknown';

  useEffect(() => {
    const initializeApp = async () => {
      // Show the native splash screen on app launch
      if (platform !== 'web') {
//        await CapacitorSplashScreen.show();
      }

      await clearIntroductionStatus();
      // Perform initialization tasks
      const introComplete = await isAppIntroductionComplete();
      setIsIntroductionDone(introComplete);

      if (platform !== 'web') {

        // Simulate an async task (e.g., API call or authentication check)
        setTimeout(() => {
          setAppInitialized(true);

          // Hide the native splash screen
          CapacitorSplashScreen.hide();
        }, 2500); // Adjust the delay as needed
      } else {
        setAppInitialized(true);
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const toastError = queryParams.get('error');
    const toastMessage = queryParams.get('message');

    if (toastError && toastMessage) {
      toast.error(decodeURIComponent(toastMessage), {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      clearQueryParams();
    } else if (toastMessage) {
      toast.success(decodeURIComponent(toastMessage), {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      clearQueryParams();
    }
  }, [location.search]);

  useEffect(() => {
    if (!localMode && !loading && data?.checkSession) {
      setIsAuthenticated(data.checkSession.authenticated);
      setUser(data.checkSession.user);

      if (!data.checkSession.authenticated) {
        deleteCookie('__session');
        console.log('Cookie deleted due to unauthenticated session.');
      }
    } else if (!loading && error) {
      setIsAuthenticated(false);
    }
  }, [loading, data, error]);

  if (error) {
    console.error('Error in GraphQL session check:', error);
    // Handle the error (e.g., show a fallback UI)
  }

  const completeIntroduction = async () => {
    await setAppIntroductionComplete();
    setIsIntroductionDone(true);
  };


  console.log(isIntroductionDone);

  if (!appInitialized) {
    // Show the React-based splash screen while the app is initializing
    return <SplashScreen />;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/auth/login" element={<Login />} />
        {/* Conditional Landing Page */}
        <Route
          path="/"
          element={
            platform == 'web' ? (
              isAuthenticated ? (
                <HomePage user={user} />  
              ) : (
                <LandingPage />  
              )
            ) : (
              isAuthenticated ? (
                <HomePage user={user} />  
              ) : (
                isIntroductionDone ? (
                  <AppIntroductionManager onComplete={completeIntroduction} />    
                ) : (
                  <Navigate to="/auth/login" replace />    
                )
              )
            )
          }
        />
        {/* Join Beta (Web Only) */}
        <Route
          path="/join-beta"
          element={platform === 'web' ? <JoinBeta /> : <Navigate to="/" replace />}
        />

        {/* Privacy Policy and Terms */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </>
  );
}

export default App;
