import { gql } from '@apollo/client';

export const CHECK_SESSION = gql`
  query CheckSession {
    checkSession {
      authenticated
      user {
        email
        firstName
        lastName
        profilePictureUrl
        onboardingStatus {
          completed
        }
      }
    }
  }
`;
