import React, { useEffect } from 'react';
import './JoinBeta.css'; // Link to the updated styles

function JoinBeta() {
  useEffect(() => {
    // Ensure the Typeform embed script runs after the component is rendered
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const setDynamicHeight = () => {
      // Calculate the full viewport height minus any browser UI elements
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set the initial height
    setDynamicHeight();

    // Update the height on resize
    window.addEventListener('resize', setDynamicHeight);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', setDynamicHeight);
  }, []);

  return (
    <div className="form-fullscreen-container">
      <div data-tf-live="01JE6JJ7BZ48VE4HARQ9J2TV41"></div>
    </div>
  );
}

export default JoinBeta;
